import React from 'react';
import type { FC } from 'react';
import { Link, graphql } from 'gatsby';
import { Layout } from '../components/layout';
// eslint-disable-next-line import/no-unresolved
import { AccessPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';
import { StaticImage } from 'gatsby-plugin-image';

interface PageProps {
  data: AccessPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO
      title="アクセス"
      description="当院へのアクセス情報です"
      keywords="アクセス"
    />
    <article id="clinical-examination">
      <h2>アクセス - Access -</h2>
      <div className="skew-border"></div>
      <section>
        <h3>アクセス</h3>
        <div className="access">
          <StaticImage
            src="../images/map.png"
            alt="病院までの地図"
            placeholder="blurred"
          />
          <p>
            〒135-0004 東京都江東区森下3-13-5 1F
            <br />
            <span className="red">専用駐車場2台あります</span>
          </p>
        </div>
      </section>
      <section>
        <h3>Google Map</h3>
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.6556566454146!2d139.8009374153453!3d35.68547988019347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018893cce517d9b%3A0x60843b8e19bbdd9b!2z44CSMTM1LTAwMDQg5p2x5Lqs6YO95rGf5p2x5Yy65qOu5LiL77yT5LiB55uu77yR77yT4oiS77yV!5e0!3m2!1sja!2sjp!4v1636870483908!5m2!1sja!2sjp"
            width="600"
            height="450"
            loading="lazy"
          ></iframe>
        </div>
        <div className="google-map-sp">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.6556566454146!2d139.8009374153453!3d35.68547988019347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018893cce517d9b%3A0x60843b8e19bbdd9b!2z44CSMTM1LTAwMDQg5p2x5Lqs6YO95rGf5p2x5Yy65qOu5LiL77yT5LiB55uu77yR77yT4oiS77yV!5e0!3m2!1sja!2sjp!4v1636870483908!5m2!1sja!2sjp"
            width="250"
            height="250"
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </article>
  </Layout>
);
export default Page;

export const query = graphql`
  query AccessPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
